import { Control, useWatch } from 'react-hook-form';
import { FinancesDrawerFormValues } from '../../RightDrawerSchema';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useState } from 'react';
import { a11yProps, TabPanel } from '../../../../../../component/TabPanel';
import ExtendedConfigurationRate from './ExtendedConfigurationRate';
import ExtendedConfigurationAids from './ExtendedConfigurationAids';
import {
  Fee,
  OptionItem,
  SalesAidLimitViolationView,
  SubsidyView,
} from '../../../../../../generated/ApiClient';
import ExtendedConfigurationFees from './ExtendedConfigurationFees';
import ExtendedConfigurationMeta from './ExtendedConfigurationMeta';
import ExtendedConfigurationViolations from './ExtendedConfigurationViolations';
import { usePermissions } from '../../../../../../common/auth/PermissionsContext';

interface Props {
  control: Control<FinancesDrawerFormValues>;
  subsidies: SubsidyView[];
  violations: SalesAidLimitViolationView[];
  isIncludeVat: boolean;
  vatFree: boolean;
  givers: OptionItem[];
  recipientUnits: { [index: number]: string };
  fees: Fee[];
}

export const ExtendedConfiguration = ({
  control,
  subsidies,
  violations,
  isIncludeVat,
  givers,
  vatFree,
  recipientUnits,
  fees,
}: Props) => {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState(0);
  const { hasPermission } = usePermissions();

  const showSalesAidLimit = hasPermission('SalesAid.LimitViolation', 'View');

  const roracUrl = useWatch({ control, name: 'credibilityCheck.roracUrl' });
  const ksvRating = useWatch({ control, name: 'credibilityCheck.ksvRating' });

  return (
    <Box sx={{ mt: 2, mb: 2 }}>
      <Divider />
      <Accordion
        sx={{
          'boxShadow': 'none',
          '&:before': {
            display: 'none',
          },
          'mt': 3,
          'mb': 2,
          'mr': 1,
        }}
        disableGutters={true}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ p: 0, mr: 0 }}
        >
          <Typography sx={{ ml: 1 }} noWrap>
            {t('deals.new.financesConfiguration.calculationDrawer.parameter.extendedConfiguration')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid item xs={12}>
              <Tabs
                aria-label="simple tab"
                value={tabValue}
                onChange={(_, newVal) => setTabValue(newVal)}
                orientation="horizontal"
                sx={{ width: '100%' }}
              >
                <Tab
                  label={t(
                    'deals.new.financesConfiguration.calculationDrawer.extendedConfiguration.rate'
                  )}
                  {...a11yProps(0)}
                />
                <Tab
                  label={t(
                    'deals.new.financesConfiguration.calculationDrawer.extendedConfiguration.aids'
                  )}
                  {...a11yProps(1)}
                />
                <Tab
                  label={t(
                    'deals.new.financesConfiguration.calculationDrawer.extendedConfiguration.fees'
                  )}
                  {...a11yProps(2)}
                />
                <Tab
                  label={t(
                    'deals.new.financesConfiguration.calculationDrawer.extendedConfiguration.meta'
                  )}
                  {...a11yProps(3)}
                />
              </Tabs>

              <TabPanel index={0} value={tabValue}>
                <ExtendedConfigurationRate
                  control={control}
                  isIncludeVat={isIncludeVat}
                  vatFree={vatFree}
                />
              </TabPanel>

              <TabPanel index={1} value={tabValue}>
                <Stack flexDirection="column" gap={4}>
                  <ExtendedConfigurationAids
                    control={control}
                    subsidies={subsidies}
                    givers={givers}
                    recipientUnits={recipientUnits}
                  />
                  {showSalesAidLimit && (
                    <ExtendedConfigurationViolations
                      violations={violations}
                      recipientUnits={recipientUnits}
                      ksvRating={ksvRating}
                      roracUrl={roracUrl}
                    />
                  )}
                </Stack>
              </TabPanel>
              <TabPanel index={2} value={tabValue}>
                <ExtendedConfigurationFees fees={fees} />
              </TabPanel>
              <TabPanel index={3} value={tabValue}>
                <ExtendedConfigurationMeta control={control} showVoting={showSalesAidLimit} />
              </TabPanel>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Divider />
    </Box>
  );
};
