import { z } from 'zod';
import {
  chassisNumber,
  dateLessOrEqualToday,
  discount,
  doubleOrNil,
  integerGreaterZero,
  radioButton,
  requiredInteger,
  requiredPosDouble,
  requiredPosNonZeroDouble,
} from '../../../../common/validation/Validations';
import {
  ApiconPriceFormUiState,
  ApiconSpecialCasePriceFormState,
  SalesAidView,
  VinState,
} from '../../../../generated/ApiClient';
import { t } from 'i18next';

const objectSchema = z.object({
  listPrice: z.number().nonnegative().min(1),
  extrasPrice: z.number().nonnegative(),
  listPriceIncludingExtras: z.number().nonnegative(),
  totalPrice: z.number().nonnegative().min(1),
  coFinancedExtras: z.number().nonnegative(),
  purchasePrice: z.number().nonnegative(),
  calculationBase: z.number().nonnegative(),
  discountPercent: discount,
  discountInEuro: requiredPosDouble,
  registrationDate: dateLessOrEqualToday.optional(),
  initialMileage: integerGreaterZero,
  commissionNumber: z.string().optional().nullable()
});

const vinSchema = z.object({
  chassisNumber: chassisNumber.optional().nullable(),
  vinDate: z.date().optional().nullable(),
  vinIssue: z.string().optional().nullable(),
  vinStatus: z.string().optional().nullable(),
  vinState: z.nativeEnum(VinState).optional().nullable()
})

export const subsidySchema = z.object({
  subsidyGiverId: requiredInteger,
  subsidyRecipientId: requiredInteger,
  fixum: doubleOrNil,
  percentage: doubleOrNil,
  isFixValue: radioButton,
  isVisible: z.boolean().optional()
});

export const salesAidSchema = z.object({
  id: doubleOrNil,
  name: z.string(),
  description: z.string().optional().nullable(),
  notice: z.string().optional().nullable(),
  specialOfferId: z.number().optional().nullable(),
  descriptionFileId: z.string().optional().nullable(),
  isEditable: z.boolean().optional(),
  subsidies: z.array(subsidySchema),
  isLocked: z.boolean().optional()
});

export const agentOptionSchema = z.object({
  id: z.number().optional(),
  title: z.string().max(50).min(1),
  price: requiredPosNonZeroDouble,
  dealerId: requiredInteger,
});

export const apiconServiceProductSchema = z.object({
  selectedServiceProductId: z.string().optional().nullable(),
  selectedProductIndustryId: z.string().optional().nullable(),
  selectedProductUsageId: z.string().optional().nullable(),
  selectedCampaignId: z.string().optional().nullable(),
  selectedProductOptions: z.array(z.string()),
  selectedTechnicalOptions: z.array(z.string()),
});

export const apiconPriceFormSchema = z.object({
  priceFormState: z.nativeEnum(ApiconSpecialCasePriceFormState),
  priceNet: z.number().nullable(),
  priceGross: z.number().nullable(),
  vatAsValue: z.number().nullable(),
  totalPrice: z.number().nullable(),
  configurationId: z.string().nullable(),
  specialCaseMandatory: z.boolean(),
});

export const apiconSpecialCaseSchema = z.object({
  priceFormState: z.nativeEnum(ApiconSpecialCasePriceFormState),
  priceFormUiState: z.nativeEnum(ApiconPriceFormUiState),
  priceNet: z.number().nullable(),
  priceGross: z.number().nullable(),
  vatAsValue: z.number().nullable(),
  comment: z.string().nullable(),
  phone: z.string().nullable(),
  email: z.string().nullable(),
  answer: z.string().nullable().optional(),
  offerId: z.string().nullable(),
  specialCaseId: z.string().nullable(),
});

export const apiconProposalFormSchema = z.object({
  proposalCreationDate: z.date().nullable().optional(),
  proposalValidUntil: z.date().nullable().optional(),
  proposalId: z.string().nullable().optional(),
});

export const serviceSchema = z.object({
  cacheGuid: z.string().nullable(),
  duration: z.number().int().nonnegative(),
  mileageId: z.number().nonnegative(),
  serviceCharge: z.number().nonnegative(),
  serviceChargeNet: z.number().nonnegative(),
  baumuster: z.string().nullable(),
  nstCode: z.string().nullable(),
  serviceVariantId: z.number().optional().nullable(),
  showModelPrototypeData: z.boolean(),
  requiresModelPrototypeData: z.boolean(),
  baumusterNstCodeReadOnly: z.boolean(),
  requestSourceApiconServiceBundleResetCalculation: z.boolean(),
  apiconServiceProduct: apiconServiceProductSchema,
  apiconPriceForm: apiconPriceFormSchema,
  apiconSpecialCase: apiconSpecialCaseSchema,
  apiconProposalForm: apiconProposalFormSchema,
});

export const conversionOptionSchema = z
  .object({
    id: z.number().optional(),
    title: z.string().max(50).min(1),
    price: requiredPosNonZeroDouble,
    supplierId: doubleOrNil,
    unknownSupplier: z.string().optional().nullable(),
    fileId: z.string().optional().nullable(),
    fileName: z.string().optional().nullable(),
    isFileArchived: z.boolean().optional().nullable(),
    file: z.string().optional().nullable(),
  })
  .refine((args) => args.supplierId || (args.unknownSupplier && args.unknownSupplier.length), {
    message: t('errors.invalid_type', { ns: 'zod' }),
    path: ['unknownSupplier'],
  });

export const credibilityCheckSchema = z
  .object({
    ksvRating: z.number().min(0).max(700).optional().nullable(),
    roracUrl: z.string().url().optional().nullable()
  });

export interface CoOptIntermFile {
  index: number;
  file: File;
  // only used temporarily in new deal creation
  financeId?: number;
}

export const schema = z
  .object({
    // calculation configuration

    specialOfferId: integerGreaterZero,
    purchaseContractDate: dateLessOrEqualToday.optional().nullable(),
    salesAid: salesAidSchema.optional(),
    dealSalesAidId: integerGreaterZero,

    financingTypeId: z.number().nonnegative(),
    duration: z.number().int().nonnegative(),
    mileageId: z.number().nonnegative(),
    residualValueId: z.number().nonnegative().optional(),
    rateCategoryId: z.number().nonnegative().optional(),
    interestRateCalculationMethodId: z.number().nonnegative(),

    accountOwner: z.string().optional().nullable(),
    downPayment: doubleOrNil,
    downPaymentPercentage: doubleOrNil,
    deposit: doubleOrNil,
    depositPercentage: doubleOrNil,
    ownResources: z.number().optional(),
    sourceOfFoundsId: z.number().optional(),
    businessRelationshipId: z.number().nonnegative(),
    residualValuePercentage: doubleOrNil,
    residualValueVehicleAbsolute: doubleOrNil,
    residualValue: doubleOrNil,
    finalRateFinancingLeasing: z.number().optional(),
    VAT: doubleOrNil,
    rateIncludingVAT: z.number().nonnegative(),
    rateExcludingVAT: z.number().nonnegative(),

    // incl. Service Values
    hasServices: z.boolean(),
    regularInstalmentTotalGross: z.number().optional(),
    regularInstalmentTotalNet: z.number().optional(),
    regularInstalmentTotalVat: z.number().optional(),

    finalInstalmentIncludingConversion: z.number().optional(),
    outstandingBalance: z.number().optional(),
    equity: z.number().optional(),
    tableResidualValue: z.number().optional(),
    residualValueConversionOptions: z.number().optional(),
    residualValueSupport: z.number().optional(),

    fundingLifetimeMin: z.number().optional(),
    fundingLifetimeMax: z.number().optional(),
    prepaymentMin: z.number().optional(),
    totalAmountPayable: z.number().optional(),
    commissionInPercent: z.number().optional().nullable(),
    commissionInNumbers: z.number().optional().nullable(),
    processingFee: z.number().optional(),
    legalTransactionFee: z.number().optional(),
    effectiveInterestRate: z.number().optional(),
    nominalInterestRate: z.number().optional(),
    fundingInterestRate: z.number().optional(),
    depositInterestRate: z.number().optional(),
    createdBy: z.string().optional().nullable(),
    creationDate: z.date().optional().nullable(),
    editedBy: z.string().optional().nullable(),
    editedDate: z.date().optional().nullable(),
    voterUser: z.string().optional().nullable(),
    voterDate: z.date().optional().nullable(),
    objectConfig: objectSchema,
    vinConfig: vinSchema,
    service: serviceSchema.deepPartial(),
    agentOptions: z.array(agentOptionSchema).optional(),
    conversionOptions: z.array(conversionOptionSchema).optional(),
    credibilityCheck: credibilityCheckSchema,
    euriborRate: z.number().optional().nullable(),

    selectedFuelServiceProductId: z.number().optional().nullable(),
    referencesCalculationId: z.number().optional().nullable(),
    referencesDealId: z.number().optional().nullable(),
    overtakeCustomer: z.boolean().optional().nullable(),
    overtakenCustomerId: z.number().optional().nullable(),
    removeReconsiderationReference: z.boolean().optional().nullable(),
  })
  .superRefine((data, ctx) => {
    if (data.residualValuePercentage === null || data.residualValuePercentage === undefined) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: t('errors.calculation.residualValueRequired'),
        fatal: false,
        path: ['residualValuePercentage'],
      });
    }
    if (
      data.residualValueVehicleAbsolute === null ||
      data.residualValueVehicleAbsolute === undefined
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: t('errors.calculation.residualValueRequired'),
        fatal: false,
        path: ['residualValueVehicleAbsolute'],
      });
    }
    if (
      data.deposit !== undefined &&
      data.deposit !== null &&
      data.deposit > 0 &&
      (data.sourceOfFoundsId === undefined || data.sourceOfFoundsId === 0)
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Mittelherkunft muss angegeben werden',
        fatal: false,
        path: ['sourceOfFoundsId'],
      });
    }
    if (
      data.downPayment !== undefined &&
      data.downPayment !== null &&
      data.downPayment > 0 &&
      (data.sourceOfFoundsId === undefined || data.sourceOfFoundsId === 0)
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Mittelherkunft muss angegeben werden',
        fatal: false,
        path: ['sourceOfFoundsId'],
      });
    }
    if (data.businessRelationshipId === 2 && data.accountOwner === undefined) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Zahlungsgeber muss bei fremder Rechnung angegeben sein',
        fatal: false,
        path: ['accountOwner'],
      });

      return z.NEVER;
    }
    if (data.businessRelationshipId === 2 && data.accountOwner === null) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Zahlungsgeber muss bei fremder Rechnung angegeben sein',
        fatal: false,
        path: ['accountOwner'],
      });

      return z.NEVER;
    }
    if (data.businessRelationshipId === 2 && data.accountOwner === '') {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Zahlungsgeber muss bei fremder Rechnung angegeben sein',
        fatal: false,
        path: ['accountOwner'],
      });

      return z.NEVER;
    }
    if (data.financingTypeId === 53 || data.financingTypeId === 31) {
      const result = serviceSchema.safeParse(data.service);

      if (!result.success) result.error.issues.forEach((i) => ctx.addIssue(i));
    }
  });

export type FinancesDrawerFormValues = z.infer<typeof schema>;
export type ObjectConfigValues = z.infer<typeof objectSchema>;
export type VinConfigValues = z.infer<typeof vinSchema>;
export type SalesAidValues = z.infer<typeof salesAidSchema>;
export type SubsidyValues = z.infer<typeof subsidySchema>;
export type AgentOptionValues = z.infer<typeof agentOptionSchema>;
export type ConversionOptionValues = z.infer<typeof conversionOptionSchema>;
export type ServiceValues = z.infer<typeof serviceSchema>;
export type SpecialCaseValues = z.infer<typeof apiconSpecialCaseSchema>;
export type CredibilityCheckValues = z.infer<typeof credibilityCheckSchema>;


export interface FinancesCalculationContextValues extends FinancesDrawerFormValues {
  // labels
  id?: number;
  financingType: string;
  residualValueLabel: string;
  rateCategory: string;
  mileage: string;
  isLeasing: boolean;
  isCredit: boolean;
  salesAids: SalesAidView[];
}

export interface RecalculateParams {
  fundingType: number;
  duration: number;
  mileageId: number;
  businessRelationshipId: number;
  interestCalculationMethodId: number | undefined;
  tariffGroup: number | undefined;
  downPayment: number | undefined;
  downPaymentPercent: number | undefined;
  deposit: number | undefined;
  depositPercent: number | undefined;
  residualValueId: number | undefined;
  sourceOfFoundsId: number | undefined;
  accountOwner: string | undefined;
  residualValueVehicleAbsolute: number | undefined;
  residualValueConversionOptions: number | undefined;
  residualValuePercentage: number | undefined;
  specialOfferId: number | undefined;
  purchaseContractDate: Date | undefined;
  objectConfiguration_ListPrice: number;
  objectConfiguration_ExtrasPrice: number;
  objectConfiguration_ListPriceIncludingExtras: number;
  objectConfiguration_TotalPrice: number;
  objectConfiguration_CoFinancedExtras: number;
  objectConfiguration_PurchasePrice: number;
  objectConfiguration_CalculationBase: number;
  objectConfiguration_DiscountPercent: number;
  objectConfiguration_DiscountInEuro: number;
  objectConfiguration_RegistrationDate: Date | undefined;
  objectConfiguration_InitialMileage: number | undefined;
  objectConfiguration_CommissionNumber: string;
  salesAid?: SalesAidValues;
  agentOptions: AgentOptionValues[];
  conversionOptions: ConversionOptionValues[];
  selectedFuelServiceProductId: number | undefined;
  referencesCalculationId: number | undefined;
  referencesDealId: number | undefined;
  service: ServiceValues;
  serviceVariantId: number | undefined;
  cacheGuid: string | undefined;
  ksvRating: number | undefined;
}
